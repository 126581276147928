<template>
  <div>
    <div>
      <h1 class="block-title bg-midnightblue">TABLEAU DE BORD <span class="font-weight-bold">DIAG</span>-INNOV-TP</h1>
    </div>

    <div class="row dashboard-filters">
      <div class="col-12">
          <div class="filters-container">
            <font-awesome-icon class="m-left vertical-center-2 blue" :icon="['fas','filter']" size="2x"/>
            <span class="filter-title blue">Filtres</span>
            <b-dropdown id="input-activities-filters" :text="activitySelectText">
                      <b-dropdown-form class="w-200">
                          <div class="selectUnselectActivities" @click="selectUnselectActivities()">
                            {{ textSelectUnselectActivities }}
                          </div>
                          <div v-for="(activity,index) in filter.activities" v-bind:key="index" class="dropdown-checkbox">
                              <b-form-checkbox
                                  v-model="activity.selected">
                                  {{ activity.name }} 
                              </b-form-checkbox>
                          </div>
                      </b-dropdown-form>
            </b-dropdown>
            <div class="relative">
              <b-form-group class="datepicker">
                <b-form-datepicker 
                          :placeholder="'Date de début…'" 
                          v-model="filter.startDate" locale="fr"
                          hide-header
                          :label-help="'Utilisez les touches directionnelles pour naviguer entre les dates'"
                      />
              </b-form-group>
              <font-awesome-icon v-if="filter.startDate != undefined" @click="resetStartDate()" style="color: black" class="resetDate" :icon="['fas','times']" size="1x"/>
            </div>
            <div class="relative">
              <b-form-group class="datepicker">
                <b-form-datepicker 
                          :placeholder="'Date de fin…'" 
                          v-model="filter.endDate" locale="fr"
                          hide-header
                          :label-help="'Utilisez les touches directionnelles pour naviguer entre les dates'"
                      />
                <font-awesome-icon v-if="filter.endDate != undefined" @click="resetEndDate()" style="color: black" class="resetDate" :icon="['fas','times']" size="1x"/>
              </b-form-group>
            </div>
          </div>
      </div>
    </div>

    <div class="row dashboard">
      <div class="col-12 col-lg-6">
        <div class="col-md-12 container-round-gray bg-whitesmoke row dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Statistiques diagnostic</p>
          <div :hidden="quizzStarted == 0 && quizzDone == 0" class="block-pie-chart col-md-12">
            <canvas id="outlabeledChartQuizz"></canvas>
          </div>
          <p v-if="quizzStarted == 0 && quizzDone == 0" class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="container-round-gray bg-whitesmoke row dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Secteurs d'activités</p>
          <div v-if="chartdataActivity != null && chartdataActivity.labels != null && chartdataActivity.labels.length != 0" class="legend-pie col-md-7">
            <ul>
              <li v-for="legend in chartActivityLegend" :key="legend.name">
                <div :style="'border: solid 1px white; background-color: ' + legend.color" class="legend-div"></div>
                <p>{{legend.label}} <span class="font-weight-bold">{{legend.nbDiag}}</span></p>
              </li>
            </ul>
          </div>
          <div v-if="chartdataActivity != null && chartdataActivity.labels != null && chartdataActivity.labels.length != 0" class="block-pie-chart col-md-5">
            <canvas hidden id="outlabeledChartActivity"></canvas>
            <quizz-pie-chart v-if="chartLoaded" class="col-md-12" id="activity-chart" :chart-data="chartdataActivity" :options="options"></quizz-pie-chart>
          </div>
          <p v-else class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="col-md-12 container-round-gray bg-whitesmoke dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Connaissance des innovations <span class="legend-dashboard">Moyenne de 0 à 5</span></p>
          <dashboard-bar-chart v-if="chartLoaded && datasetHasData(chartdataKnowledge)" class="col-md-12" id="knowledge-chart" :chart-data="chartdataKnowledge" :options="optionsKnowledge"></dashboard-bar-chart>
          <p v-if="datasetHasData(chartdataKnowledge)" class="info-bottom-bar-chart">Résultat moyen des utilisateurs au diagnostic, par axe (sur 5)</p>
          <p v-else class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="col-md-12 container-round-gray bg-whitesmoke dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Centres d'intérêts</p>
          <dashboard-bar-chart v-if="chartLoaded && datasetHasData(chartdataInterest)" class="col-md-12" id="interest-chart" :chart-data="chartdataInterest" :options="optionsInterest"></dashboard-bar-chart>
          <p v-if="datasetHasData(chartdataInterest)"  class="info-bottom-bar-chart">Fréquences des choix des centres d’intérêts</p>
          <p v-else class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="col-md-12 container-round-gray bg-whitesmoke dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Classement des innovations <span class="legend-dashboard">Moyenne de 0 à 5</span></p>
          <b-table v-if="innovationsResults != null && innovationsResults.length > 0" class="col-md-12" striped :sticky-header="true" :items="innovationsResults" :fields="fieldsInnovationsResults" responsive="md" id="table-innov-result">
          </b-table>
          <p v-else class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="col-md-12 container-round-gray bg-whitesmoke dashboard-block">
          <p class="col-md-12 bg-midnightblue dashboard-title-block text-uppercase">Classement des compétences <span class="legend-dashboard">Moyenne de 0 à 5</span></p>
          <b-table v-if="competencesResults != null && competencesResults.length > 0" class="col-md-12" striped :sticky-header="true" :items="competencesResults" :fields="fieldsCompetencesResults" responsive="md" id="table-comp-result">
          </b-table>
          <p v-else class="no-data-filtered col-md-12">Aucune données filtrées</p>
        </div>
      </div>
      <b-button hidden class="bg-darkorange" @click="toInnovationAdmin()">Editer les innovations</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getUA, isMobile } from 'mobile-device-detect';
import { forceMobile } from "@/util/util";
import API from "../api-requests";
import QuizzPieChart from "../components/chart/QuizzPieChart";
import DiagnosisResultChart from "../components/chart/DiagnosisResultChart";
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-piechart-outlabels';

Chart.plugins.register(ChartDataLabels);

export default {
  name: 'Administration',
  components: {
    'quizz-pie-chart': QuizzPieChart,
    'dashboard-bar-chart': DiagnosisResultChart
  },
  data() {
    return {
      filter : {
        activities : [],
        startDate : undefined,
        endDate: undefined
      },
      ua: getUA,
      mobile: isMobile || forceMobile().some(v => getUA.includes(v)),
      currentComponent: 'component-quizz-home',
      diagnosisCode: this.$route.params.code,
      diagnosis: this.$store.state.diagnosis,
      chartdataActivity: {},
      chartdataKnowledge: {},
      chartdataInterest: {},
      chartActivityLegend: [],
      options: {
        tooltips: {          
          bodyFontFamily: "Raleway",
          callbacks: {
          title: function(a,b) {
            return b.labels[a[0].index];
          },
          label: function(tooltipItem, data) {
            return data['datasets'][0]['data'][tooltipItem['index']];
          }
        }},
        layout: {
            padding: 10
        },
        plugins: {
          legend: false,
          datalabels: {
            display: false,
            color: '#ffffff',
            family: "Raleway"
          },            
          outlabels: {
            display: false,
          }
        }
      },
      optionsKnowledge: {
        legend: {display: false},
        responsive: true,
        maintainAspectRatio: false,
        barRoundness: 1,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 5,
              stepSize: 1,
              fontFamily: 'Raleway'
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 12,
              fontFamily: 'Raleway'
            },
          }]
        },
        tooltips: {
          bodyFontFamily: "Raleway",
          callbacks: {
            title: function() {
              return "";
            },
            label: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']];
            }
          }
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align:'end',
            font: {
              weight: 700,
              family: 'Raleway'
            },
          }
        }
      },
      optionsInterest: {
        legend: {display: false},
        responsive: true,
        maintainAspectRatio: false,
        barRoundness: 1,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
              fontFamily: 'Raleway'
            },
          }],
          xAxes: [{
            ticks: {
              fontSize: 12,
              fontFamily: 'Raleway'
            },
          }]
        },
        tooltips: {
          bodyFontFamily: "Raleway",
          callbacks: {
            title: function() {
              return "";
            },
            label: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']];
            }
          }
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align:'end',
            font: {
              weight: 700,
              family: 'Raleway'
            },
            formatter: function(value) {
              return value + "%";
            }
          }
        }
      },
      chartLoaded: false,
      fieldsCompetencesResults: [
        {key:'position', label:'Position', sortable: true},
        {key:'name', label:'Nom', sortable: true},
        {key:'avgResult', label:'Moyenne', sortable: true}
      ],
      fieldsInnovationsResults: [
        {key:'position', label:'Position', sortable: true},
        {key:'title', label:'Titre', sortable: true},
        {key:'axisName', label:'Axe', sortable: true},
        {key:'avgResult', label:'Moyenne', sortable: true}
      ],
      innovationsResults: [],
      competencesResults: [],
      activityColor: [],
      activityHoverColor: [],
      quizzStarted: 0,
      quizzDone: 0,
      colorList: [
        "#FF2121",
        "#FF6621",
        "#FFA521",
        "#EFFF21",
        "#AAFE22",
        "#51FE22",
        "#21FF85",
        "#21FFFF",
        "#21B5FF",
        "#2146FF",
        "#6021FF",
        "#DF21FF",
        "#FF2190",
        "#FF6621",
      ]
    }
  },
  watch: {
    filter: {
      handler(){
        this.getDashboardDatas();
      },
      deep:true
    }
  },
  computed: {
    size(){
      if (!this.mobile) return "col-11";
      else return "col-12";
    },
    noGutters(){
      if (!this.mobile) return "";
      else return "no-gutters";
    },
    containerFluid(){
      if (!this.mobile) return "bg-white container-fluid min-vh-100";
      else return "container-fluid-mobile min-vh-100";
    },
    activitySelectText(){
      let selectedActivities = this.filter.activities.filter(activity => activity.selected)
      if(selectedActivities.length == this.filter.activities.length){
        return "Toutes les activités";
      }
      else if(selectedActivities.length == 1){
        return selectedActivities[0].name;
      } else if(selectedActivities.length > 1){
        return selectedActivities.length + " activités séléctionées"
      }
      return "Toutes les activités";
    },
    textSelectUnselectActivities(){
      let selectedActivities = this.filter.activities.filter(activity => activity.selected);
      if(selectedActivities.length == this.filter.activities.length){
        return "Tout décocher";
      } else {
        return "Tout cocher";
      }
    }
  },
  methods: {
    resetStartDate(){
      this.filter.startDate = undefined;
    },
    resetEndDate(){
      this.filter.endDate = undefined;
    },
    selectUnselectActivities(){
      let selectedActivities = this.filter.activities.filter(activity => activity.selected);
      let allSelected = selectedActivities.length == this.filter.activities.length;

      this.filter.activities.forEach(function(activity){
        activity.selected = (!allSelected);
      })
    },
    getDashboardDatas(){

      let filter = {
        activities: this.filter.activities,
        startDate: this.filter.startDate != undefined ? new Date(this.filter.startDate).getTime() : undefined,
        endDate: this.filter.endDate != undefined ? new Date(this.filter.endDate).getTime() : undefined
      }

      API.getDashboardDatas(filter).then(response => {
        this.quizzStarted = response.data.quizzStarted;
        this.quizzDone = response.data.quizzDone;
        this.drawQuizChart();
        

      let activityValues = [];
      let activityLabels = [];
      this.chartActivityLegend = [];
      let index = 0;
      for(const activity of response.data.activities) {
        activityValues.push(activity.value);
        activityLabels.push(activity.name);

        const diagText = activity.value > 1 ? "diags" : "diag";
        this.chartActivityLegend.push({label: activity.name, nbDiag: "(" + activity.value + " " + diagText + ")", color: this.colorList[index], value: activity.value});
        index++;
      }

      this.chartdataActivity = {
        labels: activityLabels,
        datasets: [{
          label: 'activity',
          data: activityValues,
          backgroundColor: this.colorList,
          
        }]
      };

      let labels = [];
			let datas = [];
			for(const result of response.data.innovationsKnowledge) {
        let labelSplit = "AXE " + result.name;
        labelSplit= labelSplit.split(' ');
        let finalLabel = [];
        let currentLabel = "";
        for(const label of labelSplit) {
          if(currentLabel.length > 8) {
            finalLabel.push(currentLabel);
            currentLabel = "";
          }
          currentLabel += label + " ";
        }
        if(currentLabel.length > 1) {
          finalLabel.push(currentLabel);
        }
        labels.push(finalLabel);
				datas.push(Math.round(result.value*10)/10);
			}
      this.chartdataKnowledge = {
				labels: labels,
				datasets: [
					{
						backgroundColor: '#e95e27',
            data: datas,
            barThickness:20
					}
				]
      };
      
      labels = [];
			datas = [];
			for(const result of response.data.interests) {
        let labelSplit = "AXE " + result.name;
        labelSplit= labelSplit.split(' ');
        let finalLabel = [];
        let currentLabel = "";
        for(const label of labelSplit) {
          if(currentLabel.length > 8) {
            finalLabel.push(currentLabel);
            currentLabel = "";
          }
          currentLabel += label + " ";
        }
        if(currentLabel.length > 1) {
          finalLabel.push(currentLabel);
        }
        labels.push(finalLabel);
				datas.push(Math.round(result.value*1000)/10);
			}
      this.chartdataInterest = {
				labels: labels,
				datasets: [
					{
						backgroundColor: '#279ce8',
            data: datas,
            barThickness:20
					}
				]
      };

      this.chartLoaded = true;
      });

      API.getInnovationsResults(filter).then(response => {
        let currentPos = 1;
        for(let innov of response.data) {
          innov.position = currentPos;
          innov.avgResult = innov.avgResult < 0 ? "Aucune donnée" : Math.round(innov.avgResult*100)/100;
          currentPos++;
        }

        this.innovationsResults = response.data;
      });

      API.getCompetencesResults(filter).then(response => {
        let currentPos = 1;
        for(let comp of response.data) {
          comp.position = currentPos;
          comp.avgResult = Math.round(comp.avgResult*100)/100
          currentPos++;
        }
        this.competencesResults = response.data;
      });
    },
    toInnovationAdmin() {
      this.$router.push({name:"adminInnovation"});
    },
    drawQuizChart() {
      var ctx = document.getElementById('outlabeledChartQuizz').getContext('2d'); // 2d context

      new Chart(ctx, {
          type: 'outlabeledPie',
          data: {
            labels: ["Diag terminés".toUpperCase(), "Diag Non terminés".toUpperCase()],
            datasets: [{
              label: "quizz",
              data: [this.quizzStarted, this.quizzDone],
              backgroundColor: [
                '#61d66f',
                '#f1540c'
              ]
            }],
          },
          options: {
              zoomOutPercentage: 40, // makes chart 40% smaller (50% by default, if the property is undefined)
              plugins: {
                  legend: false,
                  datalabels: {
                    display: false,
                    color: '#ffffff'
                  },
                  outlabels: {
                      text: '%l - %v',
                      color: 'white',
                      stretch: 20,
                      font: {
                          resizable: true,
                          minSize: 12,
                          maxSize: 12
                      }
                  }
              }
          }
      });
    },
    datasetHasData(chartData) {
      if(chartData != null && chartData.datasets != null) {
        for(const data of chartData.datasets[0].data) {
          if(data != 0) {
            return true;
          }
        }
      }

      return false
    }
  },
  mounted() {
    API.getActivities()
		.then(response => {  
      response.data.forEach(function(data){
        data.selected = true;
      })
			this.filter.activities = response.data;
		})  
		.catch(error => {
      console.log(error);
			this.error = "Le serveur est indisponible pour le moment, merci de réessayer dans quelques minutes";
		})  
		.finally(() => this.loading = false) 
    this.getDashboardDatas();
  }
}
</script>

<style lang="scss">
.relative{
  position:relative;
}

.resetDate{
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 11px;
}

.datepicker{
  margin-bottom:0px;
  width:290px;
  margin-left: 15px;

  .form-control{
    background-color: #e4e4e4!important;
    border-radius: 0;
  }

  label { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black!important;
}
}

.selectUnselectActivities{
    padding: 1em;
    text-align: center;
    cursor:pointer;
    user-select: none;
}

.selectUnselectActivities:hover{
    background-color:#cbcbcb;
}

.filter-title{
  font-size:1.5em;
  text-transform:uppercase;
  font-weight:100;
  margin-left:5px;
  margin-right:50px;
}

  .filters-container{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    flex-wrap:wrap;
  }
  
  #input-activities-filters {
    margin-left:20px;
    width : 464px;
    height:40px;
    padding:0;

    button{
      border-radius: 0;
    }

    .dropdown-menu {
      line-height:1em;
      background-color: #e4e4e4!important;
      padding:0px;
    }

    .custom-control-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }

    .custom-control-label {
      line-height:20px;
    }
  }

  @media all and (max-width: 960px) {
    #input-activities-filters, .datepicker {
      width : 100%;
      margin-left:0px;
      margin-bottom:10px;
    }

    .filters-container {
      .relative{
        width:100%;
      }
    }
  }

  @media all and (max-width: 1404px) {
    #input-activities-filters, .datepicker {
      margin-bottom:10px;
    }
  }

  #table-innov-result, #table-comp-result {
    td {
      font-size: 0.9em;
    }

    th {
      top: -1px;
    }
  }

  .info-bottom-bar-chart {
    text-align: center;
    font-size: 0.8em;
    color: #575757;
  }

    .table-striped tbody tr:nth-of-type(even){
      background-color:$white;
    }
    .table-striped tbody tr:nth-of-type(odd){
      background-color:$whitesmoke;
    }
</style>

<style scoped lang="scss">
  @import '../css/theme.css';

  .dashboard-filters{
    box-shadow: 2px 3px 10px #b9b9b9;
    background-color:#ffffff;
    margin-top:40px;
    margin-bottom:10px;
    margin-left:50px;
    margin-right:50px;
    border-radius:none;
    line-height:60px
  }

  .b-dropdown-form {
    display: inline-block;
    padding: 0.25rem 1.5rem;
    width: 100%;
    clear: both;
    font-weight: 400;
  }

	.dashboard-title-block {
		padding:3px;
		color:white;
    padding-left: 1em;
    font-weight: 100;
    font-size: 1.3em;
    height: 45px;
    line-height:37px;
	}

		.container-round-gray {
		padding: 0;
		margin:0;
	}

	.dashboard-block {
    box-shadow: 2px 3px 10px #b9b9b9;
    padding-bottom:20px;
    height: 95%;
	}

  .dashboard {
    margin: 0;
    padding:20px;
  }

	#quizz-chart {
		position:relative;
		width:30vh;
    margin:auto;
	}

  #activity-chart {
		position:relative;
		width:30vh;
    margin:auto;
	}

  #knowledge-chart {
    position:relative;
    width:80vw;
    height: 32vh;
  }

  #interest-chart {
    position:relative;
    width:80vw;
    height: 32vh;
  }

  .block-pie-chart{
    align-self: center;
    margin-top: -3em;
  }

  .legend-div {
    width:25px;
    height:10px;
  }

  .legend-pie {
    p {
      margin: 0;
      padding-left: 10px;
      font-size:1.4vh;
    }
    ul {
      padding-left: 10px;
      list-style-type: none;

      li {
        line-height: 1.4;
        border-bottom: 1px solid #dedede;
        display: flex;
        align-items: baseline;

        p {
          max-width:90%;
          font-size:0.9em;
        }
      }

      .stat-quiz-data {
        margin-left: auto;
        font-weight:500;
      }
    }
  }
  
      
    .legend-dashboard {
      float: right;
      margin-right: 10px;
      font-size: 0.9em;
      text-transform: none;
    }

    @media all and (max-width: 767px) {
      #activity-chart{
        margin-top:40px;
      }
    }

    @media all and (max-width: 960px) {
      .dashboard-title-block {
        font-size:1em;
      }
    }

    @media all and (max-width: 1200px) {
      .legend-pie ul li {
        font-size:0.7em;
      }
      .dashboard-title-block {
        font-size:1.2em;
      }
      .legend-dashboard {
        font-size: 0.8em;
      }
    }

    .b-table-sticky-header {
      max-height: 500px;
    }

    .no-data-filtered {
      text-align: center;
    }


</style>
