<template>
  <div>
    <b-modal id="modal-xl" class="modal-rgpd" size="xl" title="Extra Large Modal" :centered="!mobile" scrollable hide-footer>
      <template #modal-header="{ close }">
        <p class="text-uppercase create-diagnosis-title blue">Diag-Innov-TP : Conditions générales d’utilisation et RGPD</p>
        <font-awesome-icon class="cursor-pointer m-left float-right blue" @click="close()" :icon="['far', 'times-circle']" size="2x"/>
      </template>
      <div class="rgpd-body">
        <p class="rgpd-p">L’utilisation du site internet <a href="www.diag-innov-tp.fr">www.diag-innov-tp.fr</a> implique l’acceptation pleine et entière par les utilisateurs des présentes conditions générales d’utilisation. Celles-ci sont susceptibles d’être modifiées à tout moment ; les utilisateurs du site <a href="www.diag-innov-tp.fr">www.diag-innov-tp.fr</a> sont donc invités à les consulter de manière régulière afin d’en prendre connaissance.</p>
        <p class="rgpd-subtitle">1- INFORMATION ÉDITEUR</p>
        <p class="rgpd-p">Le présent site, son contenu et son nom de domaine sont la propriété exclusive de :</p>
        <ul class="rgpd-list-without-point">
            <li>CONSTRUCTYS</li>
            <li>Forme juridique : Association</li>
            <li>Siège social : 32 rue René BOULANGER – CS 60033-75483 Paris Cedex 10</li>
            <li><a href="mailto:contact.dpo@constructys.fr">contact.dpo@constructys.fr</a></li>
            <li>Téléphone : 01 82 83 95 00</li>
            <li>Numéro SIREN : 533 846 150</li>
            <li>Directeur de la publication : Sylvie LEYRE </li>
        </ul>
        <p class="rgpd-p">Hébergeur :</p>
        <ul class="rgpd-list-without-point">
            <li>OVH</li>
            <li>Forme Juridique : SAS</li>
            <li>Siège social : 2, rue Kellermann, 59100 Roubaix</li>
            <li>Téléphone : 1007</li>
            <li>Courriel : contact@ovh.net</li>
            <li>RCS de Lille 537 407 926</li>
        </ul>
        <p class="rgpd-p">Créateur du site :</p>
        <ul class="rgpd-list-without-point">
            <li>BLUEPAD</li>
            <li>Forme Juridique : SAS</li>
            <li>Siège social : 92A Boulevard Solidarité 57070 METZ</li>
            <li>Téléphone : 03 87 18 08 59</li>
            <li>RCS METZ TI 818 234 387</li>
        </ul>
        <p class="rgpd-subtitle">2- PROPRIÉTE INTELLECTUELLE</p>
        <p class="rgpd-p">L’ensemble de ce site (questionnaire, textes, images, sons, graphismes, documents téléchargeables, bases de données) ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site relèvent de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. </p>
        <p class="rgpd-p">Tous les documents téléchargeables et les représentations iconographiques et photographiques présents sur le site, sont la propriété exclusive de CONSTRUCTYS et de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques sont strictement interdites. Tout autre usage du contenu doit être soumis à une autorisation préalable et écrite de CONSTRUCTYS, notamment dans le cas où l’utilisateur envisagerait de rediffuser le contenu sur un autre site. La violation de ces dispositions impératives soumet le contrevenant, et toutes personnes responsables, aux peines pénales et civiles prévues par la loi. CONSTRUCTYS se réserve le droit de modifier le site à tout moment, notamment de supprimer ou de modifier de plein droit certaines des fonctionnalités du site, sans préavis ni indemnité.</p>
        <p class="rgpd-subtitle">3 - GESTION DU SITE</p>
        <p class="rgpd-p">Pour la bonne gestion du site, CONSTRUCTYS pourra à tout moment :</p>
        <ul class="rgpd-list">
            <li>Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internaute;</li>
            <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales;</li>
            <li>Suspendre le site afin de procéder à des mises à jour.</li>
        </ul>
        <p class="rgpd-subtitle">4- LIENS HYPERTEXTES</p>
        <ul class="rgpd-list">
            <li>Les liens hypertextes présents sur le site orientant les utilisateurs vers d’autres sites Internet n’engagent pas la responsabilité de CONSTRUCTYS quant au contenu de ces sites.</li>
            <li>Toute personne physique ou morale souhaitant que soit établi un lien (adresse de son site) à partir de notre site, doit préalablement en demander l’autorisation.</li>
            <li>L’établissement de lien vers notre site ne requiert pas d’autorisation.</li>
        </ul>
        <p class="rgpd-subtitle">5- POLITIQUE DE PROTECTION DES DONNÉES PERSONNELLES (RGPD)</p>
        <p class="rgpd-p">En déposant des données personnelles sur notre site <a href="www.diag-innov-tp.fr">www.diag-innov-tp.fr</a>, vous nous autorisez à utiliser l’ensemble de ces informations en votre nom et en accord de la présente Politique de Protection des données.</p>
        <p class="rgpd-p">a) Entité responsable du traitement des données</p>
        <p class="rgpd-p">CONSTRUCTYS, Opérateur de compétences de la Construction, SIREN 533846150, est agréé en tant que tel par arrêté du 29 Juillet 2021 pour la gestion des contributions des entreprises du Bâtiment, du Négoce de Bois, du Négoce de matériaux de Construction et des Travaux publics au titre du financement des prestations de formation professionnelle et d’apprentissage.</p>
        <p class="rgpd-p">b) Type de données à caractère personnel et objectifs du recueil</p>
        <p class="rgpd-p">Les informations que nous collectons par le biais du présent site et avec votre consentement, sont des données techniques à caractère personnel. Ces données sont des données de session, nécessaires pour vous connecter ou vous reconnecter à votre diagnostic :</p>
        <ul class="rgpd-list-without-point">
            <li> - L’adresse mail de l’utilisateur,</li>
            <li> - Son numéro de téléphone, lorsqu’il est renseigné</li>
            <li> - Son nom et son prénom,</li>
            <li> - Son entreprise</li>
        </ul>
        <p class="rgpd-p">Elles servent à la réalisation du diagnostic et permettent à CONSTRUCTYS de recontacter l’utilisateur si celui-ci le demande. Ces données ne sont pas utilisées pour du démarchage commercial ou encore des statistiques. Seule la donnée activité principale de votre entreprise, qui n’est pas une donnée personnelle, peut servir à cette fin.</p>
        <p class="rgpd-p">Par ailleurs, CONSTRUCTYS donne un droit d’usage à son partenaire la FNTP (Fédération Nationale des Travaux Publics), afin de disposer de ces données dans le respect des utilisations prévues ci-dessus.</p>
        <p class="rgpd-p">Les données collectées ne sont destinées qu'à l'usage propre de CONSTRUCTYS, et le cas échéant, à celui de ses sous-traitants ainsi qu’à la FNTP dans l'exercice de leur mission. Ainsi tout sous-traitant amené à cette occasion à prendre connaissance de ces données sera soumis à une obligation de confidentialité. Il devra notamment s'interdire d'utiliser pour son propre compte ou encore de communiquer à des tiers tout ou partie de ces données à caractère personnel.</p>
        <p class="rgpd-p">c) Conservation des données collectées</p>
        <p class="rgpd-p">Les données techniques à caractère personnel sont conservées pour une durée au plus égale à 365 jours sauf demande de votre part, et passé ce délai, elles seront détruites.</p>
        <p class="rgpd-p">CONSTRUCTYS met en place les moyens organisationnels, logiciels, juridiques, techniques et physiques aptes à assurer la confidentialité et la sécurité de ces données à caractère personnel, de manière à empêcher leur endommagement, effacement ou accès par des tiers non autorisé.</p>
        <p class="rgpd-subtitle">6- COOKIES </p>
        <p class="rgpd-p">Il est spécifié que le site <a href="www.diag-innov-tp.fr">www.diag-innov-tp.fr</a> ne comporte aucun cookie, susceptible d’être mémorisé pour son fonctionnement.</p>
        <p class="rgpd-p">Pour plus d’informations sur les cookies, nous vous invitons à consulter <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs">le site de la CNIL.</a></p>
        <p class="rgpd-subtitle">7- L’EXERCICE DES DROITS ET LE DELEGUE A LA PROTECTION DES DONNEES</p>
        <p class="rgpd-p">L’ensemble des utilisateurs du diagnostic (diag-innov-tp) dispose d’un droit de réclamation, d’accès, de rectification, d’opposition, d’effacement et de limitation du traitement de ses données personnelles.</p>
        <p class="rgpd-p">Ces personnes peuvent mettre en œuvre ce droit en contactant le délégué à la protection des données désigné à cet effet par CONSTRUCTYS en sa qualité de responsable de traitement. Le délégué apporte une réponse au nom de CONSTRUCTYS dans un délai d’un mois susceptible d’être prolongé.</p>
        <p class="rgpd-p">Pour contacter le délégué à la protection des données de CONSTRUCTYS, notamment pour l’exercice de vos droits, merci d’adresser un mail à <a href="mailto;contact.dpo@constructys.fr">contact.dpo@constructys.fr</a> dans lequel vous préciserez : vos nom et prénom, le contexte dans lequel s’inscrit votre demande ou observation, ainsi que votre demande ou observation. En outre, vous devez obligatoirement, pour des raisons d’identification, y joindre la copie de votre pièce d’identité.</p>
        <p class="rgpd-p">Par ailleurs, les personnes dont les données sont collectées et traitées par CONSTRUCTYS disposent de la faculté d’introduire une réclamation auprès de la Commission Nationale d’Informatique et des Libertés (CNIL) en qualité d’autorité nationale de contrôle.</p>
        <p class="rgpd-subtitle">8- LOI APPLICABLE</p>
        <p class="rgpd-p">Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux de Paris, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.</p>
      </div>
    </b-modal>
    <div class="col-md-12">
        <div id="text-mention" class="rgpd">
          <a v-b-modal.modal-xl class="blue">Mentions légales et RGPD</a>
          </div>
      </div>
    <div class="home-footer">
        
        <div class="col-12">
          <div class="col-md-6 footer-text">
            <p class=" white">Cette action bénéficie de l'expertise et du soutien financier du ministère en charge du travail, dans le cadre de l'EDEC pour la branche des Travaux Publics.</p>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-6">
              <div class="flex-center-container">
                <img src="../../assets/logos/logoRf.png" id="img-footer-1" class="logos"/>
                <img src="../../assets/logos/constructysLogo.png" id="img-footer-3" class="logos" />
                <img src="../../assets/logos/logoFntp.svg" id="img-footer-3" class="logos"/>
              </div>
            </div>
            <div class="col-12 col-xl-6">
              <div class="flex-center-container-center">
                <span class="follow-us-text">Suivez-nous sur</span>
                <a href="https://fr-fr.facebook.com/FederationNationaleTravauxPublics/" target="_blank" class="social-link">
                  <font-awesome-icon :icon="['fab', 'facebook']" size="2x" :style="{color: 'white' }"/>
                </a>
                <a href="https://twitter.com/FNTP_info" target="_blank" class="social-link">
                  <font-awesome-icon :icon="['fab', 'twitter']" size="2x" :style="{color: 'white' }"/>
                </a>
                <a href="https://www.instagram.com/travauxpublics/" target="_blank" class="social-link">
                  <font-awesome-icon :icon="['fab', 'instagram']" size="2x" :style="{color: 'white' }"/>
                </a>   
                <a href="https://www.linkedin.com/company/f%C3%A9d%C3%A9ration-nationale-des-travaux-publics/" target="_blank" class="social-link">
                  <font-awesome-icon :icon="['fab', 'linkedin-in']" size="2x" :style="{color: 'white' }"/>
                </a>        
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
    import { getUA, isMobile } from 'mobile-device-detect';
    import { forceMobile } from "@/util/util";

    export default {
        name: "Footer",
        components: {},
        data() {
            return {
                ua: getUA,
                mobile: isMobile || forceMobile().some(v => getUA.includes(v))
            }
        },
        methods: {
        },
        computed: {
        }
    }
</script>

<style lang="scss">
    .home-footer {
		width:100%;
      background-color: rgb(81,93,101);
      color: white;
		padding: 20px;
    }

	.footer-text {
		font-size: 1em;
	}

	#img-footer {
		width: 95vw;
	}

  .rgpd{
    margin-bottom:8px;
    text-align:center;
    cursor:pointer;
  }

  .rgpd-body {
    text-align: justify;
    p, li, td {
      font-size: 0.7em;
    }
    
    a {
      color:blue;
    }

    .rgpd-list-without-point {
      list-style-type: none;
    }

    .rgpd-subtitle {
      font-weight:700;
    }
  }
  
</style>

<style scoped>
    @import '../../css/theme.css';

    .flex-center-container{
      display:flex;
      justify-content:space-around;
      align-items: center;
      height:130px;
      line-height:130px;
    }

    .flex-center-container-center{
      display:flex;
      justify-content:center;
      align-items: center;
      height:130px;
      line-height:130px;
    }

    .flex-center-container-center span, .flex-center-container-center a{
      margin-left:10px;
    }

    .logos{
      margin-right:10px;
      max-width: 300px;
      height:80%;
    }

    .social-link{
      transition:0.5s;
      border: 2px solid rgba(255, 255, 255, 0.5);
      width: 51px;
      height: 51px;
      display:flex;
    }

    .social-link:hover{
      border: 2px solid rgba(255, 255, 255, 1);
    }

    .social-link svg{
      padding:10px;
      height:51px;
      width:51px;
      line-height:51px;
    }

    .social-link svg.fa-youtube, .social-link svg.fa-facebook{
      margin-top: -2px;
      margin-left:-2px;
    }

    .social-link svg.fa-viadeo{
      margin-top: -2px;
      margin-left:1px;
    }

    .social-link svg.fa-linkedin-in, .social-link svg.fa-instagram{
      margin-top:-1px;
    }

    .follow-us-text{
      font-size: 1.5em;
      margin-right:15px;
    }

    .footer-text{
      font-weight:100;
    }

    @media all and (max-width: 960px) {
      .logos{
        margin-right:10px;
        height: 50%;
      }
      .follow-us-text{
        font-size: 0.8em;
        margin-right:15px;
      }
    }

    @media all and (max-width: 374px) {
      .follow-us-text{
        display: none;
      }
    }
</style>
